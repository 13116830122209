var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-tiendas"},[_vm._m(0),_vm._m(1),(false)?_c('div',{staticClass:"container-page-tiendas content-max-width"},[_c('div',{staticClass:"col-12 col-md-4 container-cities-page-tienda"},[_c('h4',{staticClass:"mb-4"},[_vm._v("Encuentra tu tienda más cercana")]),_c('div',{staticClass:"input-simple-clover mb-4"},[_c('label',{staticClass:"label-input",style:(_vm.select ? 'top: -14px;' : 'opacity: 0; z-index: -1;')},[_vm._v("Departamento")]),(_vm.citys.length > 0)?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.select),expression:"select"}],staticClass:"w-100",attrs:{"id":"city-select"},on:{"onChange":function($event){return _vm.selectChange(_vm.select)},"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.select=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.citys),function(city,index){return _c('option',{key:index,staticClass:"my-2",domProps:{"value":city.bodega_ciudad}},[_vm._v(" "+_vm._s(city.bodega_ciudad .toLowerCase() .replace(/^\w/, d => d.toUpperCase()))+" ")])}),0):_vm._e()]),_c('div',{staticStyle:{"max-height":"330px","overflow-y":"scroll"}},_vm._l((_vm.placeSelects),function(place,index){return _c('div',{key:index,staticClass:"p-2",class:_vm.placeSelected &&
            _vm.placeSelected.formatted_address == place.formatted_address
              ? 'place-select'
              : '',staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.selectPlace(place)}}},[_c('b',[_vm._v(_vm._s(place.name))]),_c('br'),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(place.formatted_address)+" ")])])}),0)]),_vm._m(2)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-tiendas pb-5"},[_c('h1',{staticClass:"w-100 text-center mb-5"},[_vm._v("Tiendas Clover")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 pb-5",staticStyle:{"min-height":"500px"}},[_c('iframe',{staticStyle:{"border":"0","min-height":"500px"},attrs:{"src":"https://storage.googleapis.com/maps-solutions-fzjs95mwuw/locator-plus/f2gu/locator-plus.html","width":"100%","height":"100%","loading":"lazy"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-md-8",attrs:{"id":"container-map"}},[_c('div',{staticStyle:{"min-height":"500px","height":"100%","width":"100%","position":"relative","overflow":"hidden"},attrs:{"id":"maps-content"}})])
}]

export { render, staticRenderFns }